@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Montserrat', sans-serif;
}
.loader{
  border: 8px solid green; /* Light grey */
  border-top: 8px solid red; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
}
.swiper-slide{
  @apply flex px-5 pb-10 justify-center md:space-x-7 md:px-16
}

.swiper-child{
  @apply bg-white flex flex-col-reverse p-[14px] rounded-xl shadow-2xl space-y-7 relative items-center cursor-pointer hover:bg-green-100 transit md:flex-row md:space-x-7 md:space-y-0 md:static md:flex-row 
}

::-webkit-scrollbar{
  width: 12px;
}

::-webkit-scrollbar-track{
  background: rgb(4, 121, 4);
}

::-webkit-scrollbar-thumb{
  background: red;
}

::-webkit-scrollbar-corner{
  border-radius: 10px;
}

.roundd {
  border-radius: 100%;
}

.text-deco {
  text-decoration: line-through;
}

.width {
  width: 100%;
}

.round-left {
  border-top-right-radius: 18px;
  border-bottom-left-radius: 18px;
}

.filled-btn {
  @apply py-1 px-2 text-sm bg-green-700 text-white font-bold hover:shadow-2xl transition-all rounded-lg hover:bg-transparent hover:text-green-700 md:py-3 md:px-5 md:text-base
}

.transparent-btn {
  @apply py-1 px-2 text-sm border-2 border-green-700 text-green-700 font-bold transition-all rounded-lg hover:bg-green-700 hover:text-white md:py-2 md:px-4 md:text-base
}

.padding {
  @apply py-10 px-10
}

.badge {
  background-color: green;
  padding: 10px 16px;
  border-radius: 8px;
  color: white;
}

.content-2 {
  position: relative;
  padding-bottom: 9px;
}


.content-2::after {
  content: "";
  background: linear-gradient(to right, rgb(2, 39, 2), rgb(2, 15, 2), rgb(145, 255, 145), rgb(100, 255, 100));
  width: 100px;
  height: 2px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
}


.header {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../public/images/image188.png');
  background-position: 0 -250px;
  min-height: 100%;
}

.before-footer {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../public/images/image188.png');
  height: 70vh;
  width: 100%;
  background-attachment: fixed;
  background-size: cover;
}

.filte {
  filter: brightness(60%);
  cursor: pointer;
}

.family {
  font-family: headerFont;
}

.grad {
  text-shadow: 0 0 10px green, 0 0 14px white;
}

@font-face {
  font-family: headerFont;
  src: url('./Components/RubikDoodleShadow-Regular.ttf');
}

.round {
  border-top-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

.transit {
  transition: .5s;
}


.text-shadow {
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}

.grade {
  background: linear-gradient(to right, rgb(100, 255, 100), rgb(145, 255, 145), rgb(2, 15, 2), rgb(2, 39, 2));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gap {
  gap: 50px;
}

.content-1 {
  position: relative;
  padding-bottom: 9px;
}

.content-1::after {
  content: "";
  background: linear-gradient(to right, rgb(2, 39, 2), rgb(2, 15, 2), rgb(145, 255, 145), rgb(100, 255, 100));
  width: 300px;
  height: 2px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
}

.grid-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  gap: 30px;
}

.justify-items {
  justify-items: center;
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.shadow:hover {
  box-shadow: 0px 5px 12px 1px rgba(0, 0, 0, 0.3);
}

@media(max-width: 900px) {
  .text{
    font-size: 14px;
  }
  .pad {
    padding: 0 200px;
  }

  .grid-box {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 80px;
  }

  .show {
    display: none;
  }
}

@media(min-width: 900px) {

  .content {
    position: relative;
  }

  .grid-box {
    gap: 80px 0;
  }

  .content::before {
    content: "";
    width: 0;
    height: 2px;
    background-color: green;
    position: absolute;
    transition: .4s;
  }

  .content:hover::before {
    width: 100%;
  }

}

@media(max-width: 550px) {
  .badge {
    background-color: green;
    padding: 6px 10px;
    border-radius: 8px;
    color: white;
    font-size: 12px;
  }

  .font {
    font-size: 12px;
  }
}


@media(max-width: 670px) {
  .font {
    font-size: 13px;
  }

  .text {
    font-size: 13px;
  }
  .change{
    width: 270px;
    height: 270px;
  }
}

@media(max-width: 1000px) {
  .size {
    font-size: 14px;
  }
}

.gradien {
  background: linear-gradient(90deg rgba(0, 0, 0, .3), rgba(0, 0, 0, .5));
}


@media(max-width: 480px) {
  .change{
    width: 230px;
    height: 230px;
  }

  .font {
    font-size: 11px;
  }

  .text {
    font-size: 11px;
  }

  .content-1::after {
    width: 120px;
    height: 2px;
  }

  .firstlowfont {
    font-size: 20px;
    line-height: 28px;
  }

  .firstlowfontw-lh {
    font-size: 16px;
  }

  .seclowfont {
    font-size: 10px;
  }

  #nav {
    z-index: 9999;
    box-shadow: 0px 0px 17px 6px rgba(0, 0, 0, 0.3);
  }

  .flex-col {
    flex-direction: column;
  }
}

@media(max-width: 385px) {
  .change{
    width: 200px;
    height: 200px;
  }

}